<template>
  <div class="home">
    <p>
      Welcome to my site, the main focus of which is my Discord bot.
      Available from the navigation menu at the top is a collection of pages relevant to the bot, as well as my social media links.
    </p>

    <h3>About Me</h3>

    <p>
      My name is Timo and I'm a student from Germany that normally goes by the handles of Gamu or GameVsPlayer online.
      My interests lie in in programming and gaming, and over the past 2.5 years I've been an active osu! player, never reached a high rank but was very determinated to stay active. More recently I've taken a break from osu! to relax and try something new.
    </p>
    <p>
      If you have any comments or tips, please message me via
      <a href="/twitter">Twitter</a>
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {}
};
</script>
<style scoped>
.home {
  margin-top: 4.5rem;
}
</style>